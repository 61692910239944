import { LiveConcertFieldsFragment } from 'generated/graphql';
import LiveNowIndicator from 'src/components/live-now-indicator';
import Time from 'src/components/time';
import useHasMounted from 'src/hooks/use-has-mounted';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';

type LiveConcertLabelProps = {
  liveConcert: LiveConcertFieldsFragment;
  defaultLabel?: string;
};

const dataTest = 'live-concert-label';

/**
 * A LiveConcertLabel renders a text representing a LiveConcert‘s state based on start and end time.
 * It will show a date and time if the stream hasn’t started yet, a live indicator when the concert
 * is currently ongoing. In case the concert has ended it will show either nothing or a fallback text.
 *
 * @example <LiveConcertLabel liveConcert={liveConcert} />;
 */
export default function LiveConcertLabel({ liveConcert, defaultLabel }: LiveConcertLabelProps) {
  const t = useTranslate();
  const { typeDisplayName } = liveConcert;
  const { status: liveConcertStatus, startTime } = useLiveConcertState(liveConcert);
  const hasMounted = useHasMounted();

  // Only render this component on the client because the server can have a different time zone than the client
  if (!hasMounted) return null;

  switch (liveConcertStatus) {
    case 'upcoming': {
      return startTime ? (
        <span key={startTime} data-test={dataTest}>
          <span>{typeDisplayName}&nbsp;&ndash;&nbsp;</span>
          <Time value={startTime} style="shortTime" />
        </span>
      ) : null;
    }
    case 'pre-live':
    case 'live': {
      return startTime ? (
        <time
          dateTime={startTime}
          key={startTime}
          data-test={dataTest}
          className="flex select-none items-center text-brandYellowC1"
        >
          <LiveNowIndicator />
          {t('live_concert__live_now')}
        </time>
      ) : null;
    }
    case 'past': {
      return <span data-test={dataTest}>{t('live_concert__status_label_past')}</span>;
    }
    default: {
      return defaultLabel ? <span data-test={dataTest}>{defaultLabel}</span> : null;
    }
  }
}
